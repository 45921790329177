import logo from "../src/assets/tnviso_logo.jfif";
import { FaUser, FaUserShield } from "react-icons/fa";

function App() {
  const handleNavigate = (url) => {
    window.open(url, "_blank", "noopener noreferrer");
  };

  return (
    <div className="h-screen bg-image flex items-center justify-center bg-gray-100 relative">
      {/* Dark overlay on the background */}
      <div className="absolute inset-0 bg-black opacity-50 z-0"></div>

      <div className="backdrop-blur-sm   bg-white bg-opacity-30 border border-gray-200 rounded-3xl shadow-2xl min-w-[35%] h-[70%] p-8 flex flex-col items-center justify-center z-10">
        {/* <img
          src={logo}
          className="w-24 h-24 mb-6 mix-blend-multiply rounded-full shadow-lg"
          alt="TNV logo"
        /> */}
        <h2 className="text-5xl text-white font-extrabold mb-2 drop-shadow-lg">
          TNV
        </h2>
        <h3 className="text-2xl text-gray-100 font-semibold">Welcome to TNV</h3>
        <h3 className="text-xl text-gray-200 font-medium mb-8">Login as</h3>

        {/* Login Buttons */}
        <div className="flex items-center gap-8">
          {/* Client Login */}
          <div
            onClick={() => handleNavigate("https://client.mytnv.com/auth")}
            className="flex flex-col items-center border rounded-xl justify-center w-36 h-36 p-4 shadow-lg cursor-pointer hover:shadow-xl transition duration-300 ease-in-out bg-white bg-opacity-70 backdrop-blur-lg"
          >
            <FaUser className="w-14 h-14 text-blue-600 mb-2" />
            <span className="text-lg font-bold text-gray-800">Client</span>
          </div>

          {/* Auditor Login */}
          <div
            onClick={() => handleNavigate("https://auditor.mytnv.com/auth")}
            className="flex w-36 h-36 flex-col items-center justify-center border rounded-xl p-4 shadow-lg cursor-pointer hover:shadow-xl transition duration-300 ease-in-out bg-white bg-opacity-70 backdrop-blur-lg"
          >
            <FaUserShield className="w-14 h-14 text-green-600 mb-2" />
            <span className="text-lg font-bold text-gray-800">Auditor</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
